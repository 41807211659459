import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import axios from 'app/api/axios';
import firebase from 'firebase/app';
import { authState, selectorActingAs, selectorBusinesses, selectorUser, selectorUserPermissions, selectorWarehouses } from 'app/auth/auth.reducer';
import { OrderFrontEndStateEnum } from 'app/dashboard/post-dispatch/post-dispatch.interfaces';
import { Warehouse } from 'app/interfaces/auth.interfaces';
import { CourierOrder, LastMile } from 'app/shared/shared.interfaces';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { OpTrBusinesses, OpsTrackingStore, OrderSummary } from './operations-tracking.interface';
import { DailySummaryData } from './features/daily-summary/daily-summary.interface';
import { HttpClient } from '@angular/common/http';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Team, TeamDetails } from 'app/admin/team-management/Interfaces/team-management';
import { AutoNotesTypes } from 'app/dashboard/notes/notes.constants';
import { AutoNotesService } from 'app/dashboard/notes/auto-notes.service';

@Injectable()
export class OperationsTrackingService implements OnDestroy {
  businessId: string;
  warehouses$: BehaviorSubject<Warehouse[]> = new BehaviorSubject<Warehouse[]>([]);
  businesses$: BehaviorSubject<OpTrBusinesses[]> = new BehaviorSubject<OpTrBusinesses[]>([]);
  private readonly _teams$: BehaviorSubject<TeamDetails[]> = new BehaviorSubject<TeamDetails[]>([]);
  readonly teams$ = this._teams$.asObservable();
  fleetFilter: BehaviorSubject<string> = new BehaviorSubject<string>('All Picup');
  isAdmin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  usedIds$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private _userId: string;
  private unsubscribe$ = new Subject<void>();

  set fleet(fleet: string){
    this.fleetFilter.next(fleet);
  }

  get fleet(): string{
    return  this.fleetFilter.value;
  }

  get userId(): string {
    return this._userId
  }

  set userId(id: string) {
    this._userId = id;
  }

  set usedIds(ids: string[]){
    this.usedIds$.next(ids);
  }

  constructor(private store: Store<authState>, private firestore: AngularFirestore, private httpClient: HttpClient, private router: Router, private autoNotesService: AutoNotesService) {
    this.store.select(selectorActingAs).pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
      this.businessId = res.id
    });
    combineLatest([
      this.store.select(selectorWarehouses),
      this.store.select(selectorUserPermissions),
      this.store.select(selectorUser),
      this.store.select(selectorBusinesses)
    ]).pipe(
      map(([warehouses, userPermissions, user, businesses]) => ({
        warehouses,
        userPermissions,
        user,
        businesses
      }))
      ,takeUntil(this.unsubscribe$))
      .subscribe((result: OpsTrackingStore) => {
        const {warehouses, user, businesses} = result
        this.warehouses$.next(warehouses);
        this.userId = user.user_id;
        this.businesses$.next(businesses ?? []);
        this.getTeamData();
      });

    this.isAdmin$.next(this.router.url.includes('/admin/'));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getOrderSummaryWithWarehouseOrBusinessIds(state: OrderFrontEndStateEnum , ids: string[], isAdmin: boolean, finalFailed: boolean = false, timed: boolean = false): Observable<OrderSummary[]>{
    return this.firestore.collection<OrderSummary>('order-summary', (ref) => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('FrontEndState', '==', state)
      if(isAdmin){
        query = query.where('BusinessId', 'in', ids)
      } else {
        query = query.where('WarehouseId', 'in', ids)
          .where('BusinessId', '==', this.businessId)
      }
      if(finalFailed){
        query = query.where('AllFailedFinalCollection', '==', true)
          .where('FFCHasBeenRecreated', '==', false)
      } else {
        query = query.where('AllFailedFinalCollection', '==', false)
      }
      if(timed){
        const fiveMinutesAgo = firebase.firestore.Timestamp.fromDate(new Date(Date.now() - 5 * 60 * 1000));
        query = query.where('ScheduledDate', '<', fiveMinutesAgo).orderBy('ScheduledDate');
      }
      query = query.orderBy('Timestamp');
      return query
    }).valueChanges();
  }

  getOrdersByStateAndWithNullWarehouses(state: OrderFrontEndStateEnum, orderBy: string = 'Timestamp'): Observable<OrderSummary[]> {
    return this.firestore.collection<OrderSummary>('order-summary', (ref) => {
      return ref.where('FrontEndState', '==', state)
        .where('BusinessId', '==', this.businessId)
        .where('WarehouseId', '==', null)
        .where('AllFailedFinalCollection', '==', false)
        .orderBy(orderBy);
    }).valueChanges();
  }

  getFinalFailedCollectionOrdersWithNullWarehouses(state: OrderFrontEndStateEnum, orderBy: string = 'Timestamp'): Observable<OrderSummary[]> {
    return this.firestore.collection<OrderSummary>('order-summary', (ref) => {
      return ref.where('FrontEndState', '==', state)
        .where('BusinessId', '==', this.businessId)
        .where('WarehouseId', '==', null)
        .where('AllFailedFinalCollection', '==', true)
        .where('FFCHasBeenRecreated', '==', false)
        .orderBy(orderBy);
    }).valueChanges();
  }

  getOrdersByState(state: OrderFrontEndStateEnum, orderBy: string = 'Timestamp'): Observable<OrderSummary[]> {
    return this.firestore.collection<OrderSummary>('order-summary', (ref) => {
      return ref.where('FrontEndState', '==', state)
        .where('BusinessId', '==', this.businessId)
        .where('AllFailedFinalCollection', '==', false)
        .orderBy(orderBy);
    }).valueChanges();
  }

  getFinalFailedOrdersByState(state: OrderFrontEndStateEnum, orderBy: string = 'Timestamp'): Observable<OrderSummary[]> {
    return this.firestore.collection<OrderSummary>('order-summary', (ref) => {
      return ref.where('FrontEndState', '==', state)
        .where('BusinessId', '==', this.businessId)
        .where('AllFailedFinalCollection', '==', true)
        .orderBy(orderBy);
    }).valueChanges();
  }

  getLastMileWithWarehouseOrBusinessIds(ids: string[], isAdmin: boolean ,isActive: boolean): Observable<LastMile[]>{
    return this.firestore.collection<LastMile>('last-mile', (ref) => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if(isAdmin){
        query = query.where('BusinessIds', 'array-contains-any', ids)
      } else {
        query = query.where('WarehouseIds', 'array-contains-any', ids)
      }
      if(isActive) {
        query = query.where('ActiveRouteId', '!=', null)
      } else {
        query = query.where('ActiveRouteId', '==', null)
        query = query.where('Parcels', '!=', {})
      }

      return query;
    }).valueChanges();
  }

  getAllLastMileFromBusiness(isActive: boolean): Observable<LastMile[]>{
    return this.firestore.collection<LastMile>('last-mile', (ref) => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('BusinessIds', 'array-contains-any', this.businessId)
      if(isActive) {
        query = query.where('ActiveRouteId', '!=', null)
      } else {
        query = query.where('ActiveRouteId', '==', null)
      }
      return query;
    }).valueChanges();
  }

  getActiveCourierOrders(ids: string[], isAdmin: boolean): Observable<CourierOrder[]> {
    return this.firestore
      .collection<CourierOrder>('courier-orders', (ref) => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('IsComplete', '==', false);
      query = query.where('IsCancelled', '==', false);

      if (this.businessId && !isAdmin) {
        query = query.where('BusinessId', '==', this.businessId);
        if(ids.length > 0){
          query = query.where('WarehouseId', 'in', ids)
        }
      } else if(isAdmin){
        query = query.where('BusinessId', 'in', ids)
      }
      query = query.where('TrackingEventType', '!=', 'Returned');
      query = query.orderBy('TrackingEventType');
      query = query.orderBy('Timestamp', 'desc');
      return query;
    })
      .valueChanges();
  }

  getAllCourierOrders(ids: string[], isAdmin: boolean, typeFilter: string = 'Active'): Observable<CourierOrder[]> {
    return this.firestore
      .collection<CourierOrder>('courier-orders', (ref) => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if(typeFilter === 'Complete') {
        query = query.where('IsComplete', '==', true);
        query = query.where('IsCancelled', '==', false);
      }

      if(typeFilter === 'Cancelled'){
        query = query.where('IsCancelled', '==', true);
      }

      if(typeFilter === 'Active'){
        query = query.where('IsComplete', '==', false);
        query = query.where('IsCancelled', '==', false);
      }

      if (this.businessId && !isAdmin) {
        query = query.where('BusinessId', '==', this.businessId);
        if(ids.length > 0){
          query = query.where('WarehouseId', 'in', ids)
        }
      } else if(isAdmin){
        query = query.where('BusinessId', 'in', ids)
      }
      query = query.orderBy('Timestamp', 'desc');
      return query;
    })
      .valueChanges();
  }

  getCourierOrdersWithNullWarehouses(typeFilter: string = 'Active'): Observable<CourierOrder[]> {
    return this.firestore
      .collection<CourierOrder>('courier-orders', (ref) => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if(typeFilter === 'Complete') {
        query = query.where('IsComplete', '==', true);
        query = query.where('IsCancelled', '==', false);
      }

      if(typeFilter === 'Cancelled'){
        query = query.where('IsCancelled', '==', true);
      }

      if(typeFilter === 'Active'){
        query = query.where('IsComplete', '==', false);
        query = query.where('IsCancelled', '==', false);
      }

      query = query.where('BusinessId', '==', this.businessId);
      query = query.where('WarehouseId', '==', null)
      query = query.orderBy('Timestamp', 'desc');
      return query;
    })
      .valueChanges();
  }


  async getTodaysSummary(business_id: string, warehouse_ids: string[], date: string): Promise<DailySummaryData[]> {
    return axios({
      method: 'POST',
      url: 'generic/cqrs/last-mile-order-progress',
      data: {
        business_id,
        warehouse_ids,
        collection_date_to: date,
        collection_date_from: date,
      },
    }).then((response) => {
      return response?.data;
    });
  }

  async getPostDispatchSummary(business_id: string, warehouse_ids: string[]): Promise<any> {
    return axios({
      method: 'POST',
      url: 'generic/cqrs/get-post-dispatch-summary-by-business-v2',
      data: {
        business_id,
        warehouse_ids,
        include_completed: false,
      },
    }).then((response) => {
      return response?.data;
    });
  }

  reserveOrderForUser(orderId: string): void {
    axios({
      method: 'POST',
      url: '/order/reserve-orders',
      data: {
        order_reservations: [{
          order_id: orderId,
          user_id: this.userId
        }],
      }
    }).then(() => {
      this.autoNotesService.generateNote({
        autoNotesType: AutoNotesTypes.userAssigned,
        orderId: orderId
      });
    })
  }

  unreserveOrderForUser(orderId: string): void {
    axios({
      method: 'POST',
      url: '/order/unreserve-orders',
      data: {
        order_ids: [orderId]
      },
    }).then(() => {
      this.autoNotesService.generateNote( {
        autoNotesType: AutoNotesTypes.userRemoved,
        orderId: orderId
      });
    })
  }

  getTeamData(): void{
    if(this.businessId){
      this.firestore
        .collection<Team>('teams').doc(this.businessId)
        .valueChanges().pipe(switchMap((teams) => {
          this._teams$.next(teams?.teams ?? []);
          return this._teams$;
        }), takeUntil(this.unsubscribe$)).subscribe();
    }
  }
}
