<div class="holder" *ngIf="!(isWaybillLoading$ | async); else preloader">
  <ng-container *ngIf="error; else showDefaultView">
    <ng-container *ngIf="(smsTrackingService.tokenError$ |async) === tokenError.expired; else otherErrors">
      <div class="preloader error">
        <div class="text-center">
          <svg-icon src="/assets/img/icons/parcel.svg" class="icon"></svg-icon>
          <h4>This tracking link has expired</h4>
        </div>
      </div>
    </ng-container>
    <ng-template #otherErrors>
      <ng-container>
        <div class="preloader error">
          <div class="text-center">
            <svg-icon src="/assets/img/icons/parcel.svg" class="icon"></svg-icon>
            <h4>{{errorDefaultMessage}}</h4>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </ng-container>
  <ng-template #showDefaultView>
    <ng-container *ngIf="!(view === trackingView.maponly || view === trackingView.nobanner)">
      <div class="header" [ngStyle]="{ background: businessBranding?.primary_color}">
        <img alt="Company Logo" [src]="businessBranding?.logo_url" />
      </div>
    </ng-container>
    <ng-container *ngIf="showConnectionBanner() && !this.showRating">
      <div class="banner">
        <div class="top-row">
          <div class="icon-container">
            <mat-icon class="icon">info_outline</mat-icon>
          </div>
          <div class="text-block">
            <div class="top-text">Temporary connectivity issue</div>
            <span class="bottom-text"
              >We’re sorry for the inconvenience. We are actively working to fix this and restore your real-time
              tracking.
            </span>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isSpecialCourierOrders && showSpecialCourierFinalizedBlock">
      <app-special-courier-final-screen
        [description]="renderSpecialCourierOrderParcelUpdateText()"
      ></app-special-courier-final-screen>
    </ng-container>
    <ng-container *ngIf="!showSpecialCourierFinalizedBlock">
      <ng-container *ngIf="isSpecialCourierOrders; else showDefault">
        <app-courier-order-map
          [height]="height"
          [mapMarkers]="markers"
          [driverDetails]="courierDriverDetails$ | async"
        ></app-courier-order-map>
      </ng-container>
      <ng-template #showDefault>
        <app-map-view
          *ngIf="!showRating || fsOrderData?.CourierName || view === trackingView.maponly"
          [mapType]="mapTypes.OrderTracking"
          [mapStyles]="businessBranding?.map_lightmode_enabled ? lightMapStyle : darkMapStyle"
          [driverId]="
            showDriverOnMap ? (fsLastMileMappedData?.WaypointVisits ? fsLastMileMappedData?.DriverId : null) : null
          "
          [mapStyle]="{ 'height.px': height }"
          [polylineColor]="businessBranding?.polyline_color ?? businessBranding?.primary_color"
          [polylines]="polylines"
          [polylineType]="'tracking'"
          [markers]="markers"
          [height]="height"
          [driverIcon]="getDriverIcon()"
        ></app-map-view>

        <app-driver-rating
          [driver_id]="fsLastMileMappedData?.DriverId"
          [businessId]="businessId"
          [businessReference]="businessReference"
          [driverName]="driverDetails?.name"
          *ngIf="
            showRating && !fsOrderData.courierName && view !== trackingView.maponly && view !== trackingView.nobanner && view !== trackingView.norating
          "
        >
        </app-driver-rating>
        <ng-container *ngIf="showRating && view !== trackingView.all" >
          <div class="no-rating-text">
            <h2 class="text-block">Order tracking has ended</h2>
            <div class="img-svg">
              <img [src]="'/assets/img/static/illustrations/parcels-stack.svg'" alt='test'/>
            </div>
          </div>
        </ng-container>
      </ng-template>
    </ng-container>

    <div class="slide-toggle" *ngIf="view !== trackingView.maponly" (click)="slideToggle.toggle()">
      <div class="content" [ngStyle]="{ background: businessBranding?.primary_color }">
        <svg-icon [ngStyle]="{ fill: businessBranding?.text_color}" src="/assets/img/icons/up.svg" class="icon"></svg-icon>
        <h4 [ngStyle]="{ color: businessBranding?.text_color }">
          {{ customer_text }}
        </h4>
      </div>
    </div>
    <div
      [hidden]="view === trackingView.maponly"
      class="slide-container"
      [ngStyle]="{ background: businessBranding?.secondary_color}"
      appCollapsible
      #slideToggle="appCollapsible"
      [expanded]="false"
    >
      <div class="slide-content">
        <div class="headliner" [ngStyle]="{ background: businessBranding?.primary_color }" (click)="slideToggle.toggle()">
          <svg-icon [ngStyle]="{ fill: businessBranding?.text_color }" src="/assets/img/icons/down.svg" class="icon"></svg-icon>
          <h4 [ngStyle]="{ color: businessBranding?.text_color }">
            {{ customer_text }}
          </h4>
        </div>

        <ng-container *ngIf="!showSpecialCourierFinalizedBlock && !showRating">
          <div class="order-details" *ngIf="driverDetails" [ngStyle]="{ color: businessBranding?.secondary_text_color }">
            <div class="driver">
              <img
                [src]="driverDetails?.profile_url ? driverDetails?.profile_url : '/assets/img/default-user.png'"
                alt="Driver Profile Photo"
              />
            </div>
            <div class="details">
              <div>
                <label>
                  Driver Name:
                  <h4>{{ driverDetails ? driverDetails?.name : 'No Driver Assigned' }}</h4>
                </label>
                <label>
                  Phone Number:
                  <h4 (click)="callDriver(driverDetails?.phone)">{{ driverDetails?.phone }}</h4>
                </label>
                <label>
                  Vehicle Type:
                  <h4><img [src]="'/assets/img/' + driverDetails?.active_vehicle + '.svg'" alt="vehicle Type" /></h4>
                </label>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="order-details" *ngIf="fsOrderData?.CourierName" [ngStyle]="{ color: businessBranding?.secondary_text_color }">
          <div class="details">
            <div>
              <label>
                Courier:
                <h4>{{ fsOrderData?.CourierName }}</h4>
              </label>
              <label>
                Status:
                <h4 [ngStyle]="{ color: businessBranding?.secondary_text_color }">
                  {{ customer_text }}
                </h4>
              </label>
            </div>
          </div>
        </div>

        <div class="more-details">
          <div class="details" [ngStyle]="{ color: businessBranding?.secondary_text_color }">
            <label>
              Business Reference:
              <h4>
                {{ businessReference }}
              </h4>
            </label>
            <label *ngIf="fsOrderData?.Parcels.length" [ngStyle]="{ color: businessBranding?.secondary_text_color }">
              Parcels:
              <ul>
                <li *ngFor="let parcel of fsOrderData?.Parcels">
                  <h4>{{ parcel.ParcelReference }}</h4>
                </li>
              </ul>
            </label>
            <label [ngStyle]="{ color: businessBranding?.secondary_text_color }">
              Address:
              <h4>{{ fsOrderData?.Parcels[0].Destination.FormattedAddress }}</h4>
            </label>
            <label *ngIf="fsOrderData?.Parcels[0].Destination.Contact.Instructions" [ngStyle]="{ color: businessBranding?.secondary_text_color }">
              Special Instructions:
              <h4>{{ fsOrderData?.Parcels[0].Destination.Contact.Instructions }}</h4>
            </label>
          </div>
        </div>
        <div class="footer" [ngStyle]="{ color: businessBranding?.secondary_text_color }">
          <p *ngIf="!fsOrderData?.CourierName; else showCourier">
            <span *ngIf="driverDetails?.acting_as === 0">Your delivery is being fulfilled by a Picup Legend.</span>
            <br />
            For any issues please contact Picup customer care on
            <a [href]="'tel:' + supportPhoneNumber">{{ supportPhoneNumber }}</a> or email
            <a [href]="'mailto:' + supportEmailAddress">{{ supportEmailAddress }}.</a>
          </p>
          <ng-template #showCourier>
            <p [ngStyle]="{ color: businessBranding?.secondary_text_color}">
              <span>Your delivery is being fulfilled by {{ fsOrderData.CourierName }}.</span>
              <br />
              For any issues please contact {{ fsOrderData.CourierName }} customer care on
              <a [href]="'tel:' + (fsOrderData.CourierName | courierPhone)">{{
                fsOrderData.CourierName | courierPhone
              }}</a>
            </p>
          </ng-template>
          <label>
            <h5 *ngIf="driverDetails?.acting_as === 1">Powered By:</h5>
            <img alt="Company Logo" src="/assets/img/picup-logo-white.svg" />
          </label>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<ng-template #preloader>
  <div class="preloader">
    <img [src]="'/assets/img/loader-dark.gif'" alt="" />
  </div>
</ng-template>
